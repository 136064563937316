import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Uploader from "components/Uploader/Uploader";
import Input from "components/Input/Input";
import { Textarea } from "components/Textarea/Textarea";
import Button, { KIND } from "components/Button/Button";
import DrawerBox from "components/DrawerBox/DrawerBox";
import { Grid, Row, Col } from "components/FlexBox/FlexBox";
import { Form, FieldDetails } from "../DrawerItems/DrawerItems.style";
import { FormFields, FormLabel } from "components/FormFields/FormFields";
import {
  REACT_APP_API_DEFAULT_IMAGE_URL,
  REACT_APP_API_IMAGE_URL,
  REACT_APP_API_UPLOAD_URL,
} from "settings/constants";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Spinner } from "components/Spinner/Spinner";
import NoResult from "components/NoResult/NoResult";
import { NotificationIcon } from "assets/icons/NotificationIcon";
import { useToasts } from "react-toast-notifications";
import { styled } from "baseui";
import { useModal } from "components/Modal/useModal";
import { EyeFile } from "assets/icons/EyeFile";
// import ColorPicker from "react-pick-color";

// import { settings } from "cluster";
// import groceryImage from "assets/image/themes/grocery.png";
// import groceryTwoImage from "assets/image/themes/grocery-two.png";
// import groceryTwo2Image from "assets/image/themes/grocery-two-2.png";
// import bakeryImage from "assets/image/themes/bakery.png";
// import bakery2Image from "assets/image/themes/bakery-2.png";
// import makeupImage from "assets/image/themes/makeup.png";
// import bagsImage from "assets/image/themes/bags.png";
// import bookImage from "assets/image/themes/book.png";
// import medicineImage from "assets/image/themes/medicine.png";
// import furnitureImage from "assets/image/themes/furniture.png";
// import furnitureTwoImage from "assets/image/themes/furniture-two.png";
// import clothingImage from "assets/image/themes/clothing.png";

type Props = {};

export const TypeItem = styled("div", ({ $theme }) => ({
  width: "98%",
  backgroundColor: $theme.colors.white,
  height: "50px",
  display: "flex",
  alignItems: "center",
  alignSelf: "center",
  justifyContent: "center",
  borderRadius: "5px",
  marginBottom: "15px",
  ...$theme.borders.borderEA,
  cursor: "pointer",
  position: "relative",
}));

export const TypeItemText = styled("span", ({ $theme }) => ({
  ...$theme.typography.fontBold14,
  color: $theme.colors.textDark,
}));

export const TypeItemIcon = styled("div", ({ $theme }) => ({
  color: $theme.colors.textDark,
  position: "absolute",
  transform: "translate(-50%, 50%)",
  top: "5px",
  right: "10px",
  cursor: "pointer",
}));

export const ChangedModalInner = styled("div", () => ({
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  alignContent: "center",
  alignSelf: "center",
  padding: "0",
  margin: "auto",
}));

export const ChangedModalInnerImage = styled("img", () => ({
  width: "100%",
  height: "100%",
  maxWidth: "969px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "50px",
  "object-fit": "contain",
}));

export const ChangedModalInnerImages = styled("div", () => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  flexWrap: "nowrap",
  width: "100%",
  height: "100%",
  flexDirection: "column",
}));

const GET_SETTINGS_LANDING = gql`
  query getSettings {
    settings(
      where: { key: { _ilike: "%landing%" } }
      order_by: { order: asc }
    ) {
      key
      value
      label
    }
  }
`;

const SET_SETTING = gql`
  mutation MyMutation2($objects: [settings_insert_input!] = {}) {
    insert_settings(
      objects: $objects
      on_conflict: { constraint: settings_pkey, update_columns: value }
    ) {
      affected_rows
    }
  }
`;

const LandingStaticForm: React.FC<Props> = () => {
  const { register, handleSubmit, setValue, reset } = useForm();
  const { show: showA, RenderModal } = useModal();

  const { addToast, removeAllToasts } = useToasts();
  const [name, setName] = useState("");
  const [pickerDisable, setPickerDisable] = useState(false);
  const [imageErrorText, setImageErrorText] = useState("");
  const [imageError, setImageError] = useState(false);
  const [colorPaletVisible, setColorPaletVisible] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [changedModal, setChangedModal] = useState(false);
  const [changedModalType, setChangedModalType] = useState("");
  const [settings, setSettings] = useState([]);
  const { data: settingsData, loading: settingsLoading } = useQuery(
    GET_SETTINGS_LANDING,
    {
      onCompleted: (data) => {
        console.log(data);
        setSettings(data.settings);
        data.settings.forEach((item) => {
          setValue(item.key, item.value);
        });
        // reset(
        //   data.settings.map((item) => {
        //     return { [item.key]: item.value };
        //   })
        // );
      },
    }
  );
  const [
    updateSettings,
    { loading: setSettingsLoading, error: setSettingsError },
  ] = useMutation(SET_SETTING, {
    onCompleted(complete) {
      if (complete.insert_settings.affected_rows > 0) {
        // removeAllToasts()
        // addToast("Ayarlar güncellendi.", {
        //   appearance: "success",
        // });
        setUpdated(true);
      } else {
        addToast("Ayarlar güncellemesi işlemi sırasında bir hata oluştu.", {
          appearance: "warning",
        });
        addToast("Lütfen tekrar deneyin.", {
          appearance: "info",
        });
      }
    },
  });

  useEffect(() => { }, [register]);

  useEffect(() => {
    setTimeout(function () {
      updated && setUpdated(false);
    }, 5000);
  }, [setUpdated]);



  const onSubmit = async (data: any) => {
    let settings = Object.entries(data).map(item => {
      return {
        key: item[0],
        value: item[1]
      }
    });
    await updateSettings({
      variables: {
        objects: settings
      }
    })


    addToast("Ayarlar güncellendi.", {
      appearance: "success",
    });
  };

  return (
    <Grid fluid={true}>
      {setSettingsError && (
        <Row
          align="center"
          style={{
            position: "fixed",
            right: 40,
          }}
        >
          <NotificationIcon />
          <FieldDetails style={{ padding: 0, marginLeft: 10 }}>
            Bir hata oluştu
            <Button
              kind={KIND.minimal}
              overrides={{
                BaseButton: {
                  style: ({ $theme }) => ({
                    paddingLeft: 0,
                    paddingBottom: 0,
                    paddingTop: 0,
                    paddingRight: 0,
                    color: $theme.colors.red400,
                  }),
                },
              }}
              onClick={() => {
                setTimeout(() => {
                  window.location.reload();
                }, 500);
              }}
            >
              tekrar deneyin.
            </Button>
          </FieldDetails>
        </Row>
      )}

      {!settingsLoading ? (
        settingsData ? (
          <Form onSubmit={handleSubmit(onSubmit)} style={{ paddingBottom: 0 }}>
            <Row>
              <Col md={4}>
                <FieldDetails>
                  Anasayfadaki alanları buradan düzenleyebilirsiniz.
                </FieldDetails>
              </Col>

              <Col md={8}>
                <DrawerBox>
                  {settings.map((item, index) => {
                    return (
                      <FormFields key={index}>
                        <FormLabel>{item.label}</FormLabel>
                        <Input
                          name={item.key}
                          inputRef={register}
                          onChange={(e: {
                            target: { value: React.SetStateAction<string> };
                          }) => setValue(item.key, e.target.value)}
                        />
                      </FormFields>
                    );
                  })}
                </DrawerBox>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <FormFields>
                  <Button
                    disabled={pickerDisable}
                    type="submit"
                    overrides={{
                      BaseButton: {
                        style: () => ({
                          width: "50%",
                          color: "black",
                          marginLeft: "auto",
                          borderTopLeftRadius: "3px",
                          borderTopRightRadius: "3px",
                          borderBottomLeftRadius: "3px",
                          borderBottomRightRadius: "3px",
                        }),
                      },
                    }}
                  >
                    Güncelle / Kaydet
                  </Button>
                </FormFields>
              </Col>
            </Row>
          </Form>
        ) : (
          <NoResult />
        )
      ) : (
        <Spinner />
      )}
    </Grid>
  );
};

export default LandingStaticForm;
