import { gql } from '@apollo/client';
import { styled, withStyle, createThemedUseStyletron } from 'baseui'
import { Row as Rows, Col as Column } from 'components/FlexBox/FlexBox'
import { PAYMENT_TYPE_TEXT, REFUND_STATUS } from 'settings/constants';

export const GET_ANNOUNCEMENTS = gql`
  query announcement($_ilike: String = "%%") {
    announcement(order_by: {order: asc}) {
      created_at
      id
      title
      url
      deleted_at
      button_title
      order
      is_active
      foreign_key
      updated_at
      page {
        id
        title
        summary
        cover_image
        slug
      }
      blog {
        id
        title
        summary
        cover_image
        slug
      }
      product {
        id
        name
        summary
        unit_price
        cover_image
        slug
      }
    }
  }
`;

export const EDIT_ANNOUNCEMENT = gql`
  mutation editAnnouncement($id: uuid!, $_set: announcement_set_input!) {
    update_announcement_by_pk(pk_columns: {id: $id}, _set: $_set) {
      title
      id
    }
  }
`

export const UPDATE_ANNOUNCEMENT = gql`
  mutation editSpesificValuesAnnouncement($id: uuid = "", $_set: announcement_set_input = {}) {
    update_announcement_by_pk(pk_columns: {id: $id}, _set: $_set) {
        id
      }
  }
`

type CustomThemeT = { red400: string; textNormal: string; colors: any }
export const themedUseStyletron = createThemedUseStyletron<CustomThemeT>()
export const PAYMENT_TYPE_TEXT_DATA = PAYMENT_TYPE_TEXT;
export const REFUND_STATUS_DATA = REFUND_STATUS;
export type PagesProps = any

export const Col = withStyle(Column, () => ({
  '@media only screen and (max-width: 767px)': {
    marginBottom: '20px',

    ':last-child': {
      marginBottom: 0
    }
  }
}))

export const Row = withStyle(Rows, () => ({
  '@media only screen and (min-width: 768px)': {
    alignItems: 'center'
  }
}))

export const Status = styled('div', ({ $theme }) => ({
  ...$theme.typography.fontBold14,
  color: $theme.colors.textDark,
  display: 'flex',
  alignItems: 'center',
  lineHeight: '1',
  textAlign: 'center',
  textTransform: 'capitalize',

  ':before': {
    content: '""',
    width: '10px',
    height: '10px',
    display: 'inline-block',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    borderBottomRightRadius: '10px',
    borderBottomLeftRadius: '10px',
    backgroundColor: $theme.borders.borderE6,
    marginRight: '10px'
  }
}))

export const PagesGridHeader = styled('header', () => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flex: '0 1 auto',
  flexDirection: 'row',
  flexWrap: 'wrap',
  padding: '40px',
  /* margin-bottom: 40px, */
  backgroundColor: '#ffffff',
  marginBottom: 30,
  boxShadow: '0 0 8px rgba(0, 0 ,0, 0.1)',

  '@media only screen and (max-width: 990px)': {
    padding: '20px',
  },
}));

export const TableWrapper = styled('div', () => ({
  width: '100%',
  height: '450px',
}));
