import { gql, useQuery } from '@apollo/client';
import Button from 'components/Button/Button';
import dayjs from 'dayjs';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import DataGrid, { DataGridHandle } from 'react-data-grid';
import { CellExpanderFormatter } from './components/CellExpanderFormatter';
import './style.css';
import { Header, Heading } from 'components/Wrapper.style';
import { CheckAlternate } from 'assets/icons/checkalternate';
import { useDrawerDispatch, useDrawerState } from 'context/DrawerContext';
import { Row, Col } from 'components/FlexBox/FlexBox';
import { Spinner } from 'components/Spinner/Spinner';

const AFFILATE = gql`
  query AFFILATE {
    coupons(where: { affiliate: { id: { _is_null: false } } }) {
      affiliate {
        affiliate_reward_histories_aggregate {
          aggregate {
            sum {
              reward_price
            }
          }
          nodes {
            id
            total_price
            reward_price
            status
            created_at
          }
        }
        phone
        email
        first_name
        last_name
        id
      }
      code
    }
  }
`;

function Affiliate() {
  const [rows, setRows] = useState([]);
  const [data, setData] = useState([]);
  // const [sortColumns] = useState([]);
  const [type, setType] = useState(true);

  const { loading, refetch } = useQuery(AFFILATE, {
    onCompleted: (data) => {
      if (data?.coupons) {
        let users = data?.coupons.map((user) => {
          user.affiliate.sum =
            user.affiliate.affiliate_reward_histories_aggregate.aggregate.sum
              .reward_price ?? 0;
          delete user.affiliate.__typename;
          user.affiliate.name =
            user.affiliate.first_name + ' ' + user.affiliate.last_name;
          user.affiliate.type = 'MASTER';
          user.affiliate.code = user.code;
          return user.affiliate;
        });
        let details = data?.coupons.map((user) => {
          user.affiliate.sum =
            user.affiliate.affiliate_reward_histories_aggregate.aggregate.sum
              .reward_price ?? 0;
          delete user.affiliate.__typename;
          user.affiliate.name =
            user.affiliate.first_name + ' ' + user.affiliate.last_name;
          return {
            user_id: user.affiliate.id,
            rows: user.affiliate.affiliate_reward_histories_aggregate.nodes.map(
              (item) => {
                if (item.reward_price > 0) {
                  item.type = 'Kupon Kullanıldı';
                } else {
                  item.type = 'Harcama/Ödeme Yapıldı';
                }
                item.created_at = dayjs(item.created_at).format('DD.MM.YYYY');
                return item;
              }
            ),
          };
        });
        setData(details);
        setRows(users);
      }
      // setRows(data.forms);
    },
  });

  const dataW = useDrawerState('data');

  useEffect(() => {
    if (dataW === null) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataW]);

  // function getComparator(sortColumn) {
  //   switch (sortColumn) {
  //     case 'id':
  //     case 'title':
  //       return (a, b) => {
  //         return a[sortColumn].localeCompare(b[sortColumn]);
  //       };
  //     default:
  //       return (a, b) => {
  //         return a;
  //       };
  //   }
  // }

  // const summaryRows = useMemo(() => {
  //   const summaryRow = {
  //     id: 'total_0',
  //     totalCount: rows.length,
  //   };
  //   return [summaryRow];
  // }, [rows]);

  // const sortedRows = useMemo(() => {
  //   if (sortColumns.length === 0) return rows;

  //   return [...rows].sort((a, b) => {
  //     for (const sort of sortColumns) {
  //       const comparator = getComparator(sort.columnKey);
  //       const compResult = comparator(a, b);
  //       if (compResult !== 0) {
  //         return sort.direction === 'ASC' ? compResult : -compResult;
  //       }
  //     }
  //     return 0;
  //   });
  // }, [rows, sortColumns]);

  const columns = [
    {
      key: 'expanded',
      name: '',
      minWidth: 30,
      width: 30,
      colSpan(args) {
        return args.type === 'ROW' && args.row.type === 'DETAIL'
          ? 6
          : undefined;
      },
      cellClass(row) {
        return row.type === 'DETAIL' ? 'rowDetail' : undefined;
      },
      formatter({ row, isCellSelected, onRowChange }) {
        if (row.type === 'DETAIL') {
          return (
            <AffiliateDetail
              data={data}
              isCellSelected={isCellSelected}
              parentId={row.parentId}
              direction={'ltr'}
            />
          );
        }

        return (
          <CellExpanderFormatter
            expanded={row.expanded}
            isCellSelected={isCellSelected}
            onCellExpand={() => {
              onRowChange({ ...row, expanded: !row.expanded });
            }}
          />
        );
      },
    },
    {
      key: 'name',
      name: 'Adı - Soyad',
    },
    {
      key: 'phone',
      name: 'Telefon Numarası',
    },
    {
      key: 'email',
      name: 'Email',
    },
    {
      key: 'code',
      name: 'Kod',
    },
    {
      key: 'sum',
      name: 'Hakediş',
    },
  ];

  function onRowsChange(rows, { indexes }) {
    const row = rows[indexes[0]];
    if (row.type === 'MASTER') {
      if (!row.expanded) {
        rows.splice(indexes[0] + 1, 1);
      } else {
        rows.splice(indexes[0] + 1, 0, {
          type: 'DETAIL',
          id: row.id + 100,
          parentId: row.id,
        });
      }
      console.log(rows);

      setRows(rows);
    }
  }

  if (loading) {
    return <Spinner />;
  }

  return (
    <Row>
      <Col md={12}>
        <Header
          style={{
            marginBottom: 30,
          }}
        >
          <Col
            style={{
              marginBottom: 30,
            }}
            md={12}
          >
            <Heading>Satış Ortaklığı Detay ve Aktiviteleri</Heading>
          </Col>
          <Col md={10}>
            <Row>
              <Col md={4}>
                <Button
                  onClick={() => {
                    setType(true);
                  }}
                  overrides={{
                    BaseButton: {
                      style: ({ $theme, $size, $shape }) => {
                        return {
                          width: '100%',
                          borderTopLeftRadius: '3px',
                          borderTopRightRadius: '3px',
                          borderBottomLeftRadius: '3px',
                          borderBottomRightRadius: '3px',
                        };
                      },
                    },
                  }}
                >
                  Satış Ortaklığı Aktiviteleri
                </Button>
              </Col>
              <Col md={4}>
                <Button
                  onClick={() => {
                    setType(false);
                  }}
                  overrides={{
                    BaseButton: {
                      style: ({ $theme, $size, $shape }) => {
                        return {
                          width: '100%',
                          borderTopLeftRadius: '3px',
                          borderTopRightRadius: '3px',
                          borderBottomLeftRadius: '3px',
                          borderBottomRightRadius: '3px',
                        };
                      },
                    },
                  }}
                >
                  Satış Ortaklığı Başvuruları
                </Button>
              </Col>
            </Row>
          </Col>
        </Header>
      </Col>
      <Col md={12}>
        {!type ? (
          <AffiliateApplies />
        ) : (
          <DataGrid
            rowKeyGetter={rowKeyGetter}
            columns={columns}
            rows={rows}
            onRowsChange={onRowsChange}
            headerRowHeight={45}
            style={{ height: '60vh' }}
            rowHeight={(args) =>
              args.type === 'ROW' && args.row.type === 'DETAIL' ? 300 : 45
            }
            className="fill-grid rdg-light"
            enableVirtualization={false}
            direction={'ltr'}
          />
        )}
      </Col>
    </Row>
  );
}

const affiliateDetailColumns = [
  { key: 'total_price', name: 'Alışveriş Tutarı' },
  { key: 'reward_price', name: 'Hakedilen Para' },
  { key: 'type', name: 'Aktivite detayı' },
  { key: 'created_at', name: 'Tarih' },
];
function AffiliateDetail({ parentId, data, isCellSelected, direction }) {
  const gridRef = useRef<DataGridHandle>(null);
  useEffect(() => {
    if (!isCellSelected) return;
    gridRef
      .current!.element!.querySelector<HTMLDivElement>('[tabindex="0"]')!
      .focus({ preventScroll: true });
  }, [isCellSelected]);

  const details = data.find((item) => item.user_id === parentId).rows;
  function onKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
    if (event.isDefaultPrevented()) {
      event.stopPropagation();
    }
  }

  return (
    <div onKeyDown={onKeyDown}>
      <DataGrid
        className="rdg-light"
        ref={gridRef}
        rows={details}
        columns={affiliateDetailColumns}
        rowKeyGetter={rowKeyGetter}
        style={{ height: 'auto' }}
        direction={direction}
      />
    </div>
  );
}
function rowKeyGetter(row) {
  return row.id;
}

const GET_FORM_ANSWERS = gql`
  query GET_FORM_ANSWERS {
    form_answers(where: { form: { slug: { _eq: "satis-ortakligi" } } }) {
      answers
      id
      form {
        form
      }
      user {
        id
        email
        first_name
        last_name
        phone
        coupons {
          code
        }
      }
    }
  }
`;

function AffiliateApplies() {
  const [columns, setColumns] = useState([
    { key: 'created_at', name: 'Tarih' },
  ]);
  const [answers, setAnswers] = useState([]);
  const dispatch = useDrawerDispatch();
  const openDrawer = useCallback(
    (data) =>
      dispatch({
        type: 'OPEN_DRAWER',
        drawerComponent: 'COUPON_FORM',
        data: data,
      }),
    [dispatch]
  );
  const { loading } = useQuery(GET_FORM_ANSWERS, {
    onCompleted: (data) => {
      console.log(data);
      if (data.form_answers.length > 0) {
        let columns = data.form_answers[0].form.form
          .filter((p) => p.type !== 'h1')
          .map((p) => {
            return { key: p.name, name: p.label };
          });
        columns.unshift({
          name: 'Aksiyon',
          key: 'action',
          width: 50,
          formatter: ({ row }) => (
            <div
              onClick={() => {
                console.log(row);
                openDrawer(row);
              }}
              style={{
                cursor: 'pointer',
                height: '100%',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CheckAlternate />
            </div>
          ),
        });
        // columns.push({ key: 'user', name: 'Kullanıcı' })
        setColumns(columns);

        setAnswers(
          data.form_answers
            .filter((p) => p.user.coupons.length === 0)
            .map((item) => {
              item.answers.user_id = item.user.id;
              item.answers.user_name =
                item.user.first_name + ' ' + item.user.last_name;
              return item.answers;
            })
        );
      }
    },
  });
  if (loading) {
    return <p>Yükleniyor...</p>;
  }
  return (
    <DataGrid
      className="rdg-light"
      rows={answers}
      columns={columns}
      rowKeyGetter={rowKeyGetter}
      style={{ height: 'auto' }}
    />
  );
}

export default Affiliate;
