const Spin = ({ color }) => {
  return (
    <div>
      <div className="wrapper">
        <div className="ball"></div>
        <div className="ball"></div>
        <div className="ball"></div>
      </div>
      <svg>
        <defs>
          <filter id="filter">
            <feGaussianBlur
              in="SourceGraphic"
              stdDeviation="18"
              result="blur"
            />
            <feColorMatrix
              in="blur"
              mode="matrix"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 28 -10"
              result="filter"
            />
            <feComposite
              in="SourceGraphic"
              in2="filter"
              operator="atop"
            />
          </filter>
        </defs>
      </svg>
    </div>
  );
};

export const Spinner = ({ ...props }) => {
  props.notStyle = props.notStyle ? props.notStyle : false;
  return props.notStyle ? (
    <Spin color={props.color ? props.color : 'black'} />
  ) : (
    <Spin color={props.color ? props.color : 'black'} />
  );
};
