import { CloseSquareO } from 'assets/icons/CloseSquare'
import React from 'react'
import ReactDOM from 'react-dom'

type Props = {
  children: React.ReactChild
  closeModal: () => void
}

const Modal = React.memo(({ children, closeModal }: Props) => {
  const domEl = document.getElementById('modal-root')
  document.getElementById('root').style.filter = 'blur(5px)'

  if (!domEl) return null
  
  return ReactDOM.createPortal(
    <div className="modal--inner">
      <button className="close--modal" onClick={closeModal}>
        <CloseSquareO width={40} height={40} color={"white"} />
      </button>
      {children}
    </div>,
    domEl
  )
})

export default Modal