import React, { useState } from 'react'

import Modal from './Modal'
export const useModal = () => {
  const [isVisible, setIsVisible] = useState(false)

  const show = () => {
    setIsVisible(true);
    document.getElementById('root').style.filter = 'blur(5px)';
  }
  const hide = () => {
    document.getElementById('root').style.filter = 'blur(0px)';
    setIsVisible(false)
  }
  
  const RenderModal = ({ children }: { children: React.ReactChild }) => (
    <React.Fragment>
      {isVisible && <Modal closeModal={hide}>{children}</Modal>}
    </React.Fragment>
  )

  return {
    show,
    hide,
    RenderModal,
  }
}