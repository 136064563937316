import { useContext, lazy, Suspense } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import {
  LOGIN,
  PRODUCTS,
  CATEGORY,
  DASHBOARD,
  ORDERS,
  SETTINGS,
  CUSTOMERS,
  BRANDS,
  COUPONS,
  CAMPAIGNS,
  STAFF_MEMBERS,
  SITE_SETTINGS,
  PAGES,
  BLOGS,
  SLIDERS,
  MENU,
  PRODUCT_CREATE,
  PRODUCT_EDIT,
  REFUNDS,
  B2B_ROUTE,
  ANNOUNCEMENT,
  FAQ,
  FORMS,
  FORM_BUILDER,
  AFFILIATE,
  TAGS,
  COMMENTS,
  REFERENCES,
  EMAIL_LIST,
  LANDING_SETTINGS,
  BLOG_CATEGORY,
} from "settings/constants";
import AuthProvider, { AuthContext } from "context/auth";
import { InLineLoader } from "components/InlineLoader/InlineLoader";
import Affiliate from "containers/Affiliate/Affiliate";
import LandingStaticForm from "containers/LandingStaticForm/LandingStaticForm";
import BlogCategory from "containers/BlogCategory/Category";

const Products = lazy(() => import("containers/Products/Products"));
const ProductCreate = lazy(() => import("containers/Products/ProductCreate"));
const ProductEdit = lazy(() => import("containers/Products/ProductEdit"));
const AdminLayout = lazy(() => import("containers/Layout/Layout"));
const Dashboard = lazy(() => import("containers/Dashboard/Dashboard"));
const Category = lazy(() => import("containers/Category/Category"));
const Orders = lazy(() => import("containers/Orders/Orders"));
const Settings = lazy(() => import("containers/Settings/Settings"));
const SiteSettingForm = lazy(
  () => import("containers/SiteSettingForm/SiteSettingForm")
);
const StaffMembers = lazy(() => import("containers/StaffMembers/StaffMembers"));
const Pages = lazy(() => import("containers/Pages/Pages"));
const Blogs = lazy(() => import("containers/Blogs/Blogs"));
const Sliders = lazy(() => import("containers/Sliders/Sliders"));
const Customers = lazy(() => import("containers/Customers/Customers"));
const Brands = lazy(() => import("containers/Brands/Brand"));
const Coupons = lazy(() => import("containers/Coupons/Coupons"));
const Campaigns = lazy(() => import("containers/Campaigns/Campaigns"));
const Login = lazy(() => import("containers/Login/Login"));
const NotFound = lazy(() => import("containers/NotFound/NotFound"));
const Refunds = lazy(() => import("containers/Refunds/Refunds"));
const B2B = lazy(() => import("containers/B2B/B2B"));
const Menu = lazy(() => import("containers/Menu/Menu"));
const Announcement = lazy(() => import("containers/Announcement/Announcement"));
const Faq = lazy(() => import("containers/Faq/Faq"));
const Builder = lazy(
  () => import("containers/Forms/FormBuilder/BuilderPage/Builder")
);
const Forms = lazy(() => import("containers/Forms/Forms"));
const Tags = lazy(() => import("containers/Tags/Tags"));
const Comments = lazy(() => import("containers/Comments/Comments"));
const References = lazy(() => import("containers/References/References"));
const EmailList = lazy(() => import("containers/EmailList/EmailList"));

function PrivateRoute({ children, ...rest }) {
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

const Routes = () => {
  return (
    <AuthProvider>
      <Suspense fallback={<InLineLoader />}>
        <Switch>
          <PrivateRoute exact={true} path={DASHBOARD}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Dashboard />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={FORMS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Forms />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>{" "}
          <PrivateRoute exact={true} path={FORM_BUILDER}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Builder />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={PRODUCTS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Products />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={MENU}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Menu />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={ANNOUNCEMENT}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Announcement />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={FAQ}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Faq />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={PRODUCTS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Products />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={PRODUCT_CREATE}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <ProductCreate />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={"/product/store"}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <ProductCreate />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={PRODUCT_EDIT}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <ProductEdit />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={CATEGORY}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Category />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={ORDERS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Orders />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={REFUNDS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Refunds />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={B2B_ROUTE}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <B2B />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={CUSTOMERS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Customers />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={AFFILIATE}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Affiliate />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={BRANDS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Brands />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={COUPONS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Coupons />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={CAMPAIGNS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Campaigns />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={SETTINGS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Settings />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={LANDING_SETTINGS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <LandingStaticForm />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={STAFF_MEMBERS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <StaffMembers />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={TAGS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Tags />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={COMMENTS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Comments />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={REFERENCES}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <References />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={EMAIL_LIST}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <EmailList />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={PAGES}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Pages />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={BLOG_CATEGORY}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <BlogCategory />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={BLOGS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Blogs />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={SLIDERS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Sliders />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={SITE_SETTINGS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <SiteSettingForm />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <Route path={LOGIN}>
            <Login />
          </Route>
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </AuthProvider>
  );
};

export default Routes;
