import React, {
  useState,
  useEffect,
  useCallback,
  Component,
  Fragment,
} from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useToasts } from 'react-toast-notifications';
import SortableTree from 'react-sortable-tree';
import Button from 'components/Button/Button';
import {
  Col,
  Row,
  CategoryCardWrapperOrdering,
  CategoryCardWrapperOrderingSaveButtonArea,
  CategoryLoaderWrapper,
  CategoryLoaderItem,
} from './Category.style';

import {
  GET_CATEGORIES,
  TreeCategoryType,
  TreeCategoryChildrenType,
  GET_CATEGORIES_tree,
  EDIT_CATEGORIES_tree,
  priceSelectOptions,
} from './utils';
import { Header, Heading } from 'components/Wrapper.style';
import { Grid } from 'components/FlexBox/FlexBox';
import { useDrawerDispatch, useDrawerState } from 'context/DrawerContext';
import { Spinner } from 'components/Spinner/Spinner';
import NoResult from 'components/NoResult/NoResult';
import Placeholder from 'components/Placeholder/Placeholder';
import { Plus } from 'assets/icons/PlusMinus';
import DataGridGroupingCategories from './DataGrid_Grouping';
import {
  ExportIcon,
  PlusIcon,
  SortingIcon,
} from 'components/MixingComponents/components/Icons';
import 'react-sortable-tree/style.css';
import { ExportButton } from './DataGrid_Grouping/ExportButton';
import { DefaultPageHeader } from 'components/DataGrid/components/DefaultHeader';

export default function BlogCategory() {
  // const [loadingMore, toggleLoading] = useState(false)
  // const [categoryProductOrder, setCategoryProductOrder] = useState([])
  // const [search, setSearch] = useState([])
  const dispatch = useDrawerDispatch();
  const dataW = useDrawerState('data');
  const { addToast } = useToasts();
  const openDrawer = useCallback(
    () =>
      dispatch({ type: 'OPEN_DRAWER', drawerComponent: 'BLOG_CATEGORY_FORM' }),
    [dispatch]
  );

  //pagination
  const [limit] = useState(100);
  const [offset] = useState(0);
  const [toffset, setToffset] = useState(0);
  const [isEnd, setIsEnd] = useState(false);
  const [categoryTreeData, setCategoryTreeData] = useState([]);
  const [categoryTreeDataOrderingState, setCategoryTreeDataOrderingState] =
    useState(false);

  const { loading, data, error, fetchMore, refetch } = useQuery(
    GET_CATEGORIES,
    {
      variables: {
        limit,
        offset,
        _ilike: '%%',
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    }
  );

  const { loading: loadingtree, data: datatree } = useQuery(
    GET_CATEGORIES_tree,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    }
  );

  const [
    editCategoriesTree,
    { loading: editCategoryLoading, error: editCategoryError },
  ] = useMutation(EDIT_CATEGORIES_tree, {
    update(cache, { data }) {
      cache.writeQuery({
        query: GET_CATEGORIES,
        data,
      });
    },
    onCompleted(complete) {
      if (complete.insert_categories.affected_rows > 0) {
        addToast('Kategorilerin sıralaması güncellendi.', {
          appearance: 'success',
        });
      } else {
        addToast(
          'Kategori sıralama güncellemesi işlemi sırasında bir hata oluştu.',
          {
            appearance: 'warning',
          }
        );
        addToast('Lütfen tekrar deneyin.', {
          appearance: 'info',
        });
      }
    },
  });

  useEffect(() => {
    if (dataW === null) {
      refetch();
    }
  }, [dataW]);

  useEffect(() => {
    if (
      data !== undefined &&
      data.blog_categories &&
      toffset === data.blog_categories.length
    ) {
      setIsEnd(true);
    } else {
      setIsEnd(false);
    }
  }, [toffset, data, loading]);

  if (error) {
    return <div>Bir hata oluştu! {error.message}</div>;
  }

  return (
    <Grid fluid={true}>
      <Row>
        <Col md={12}>
          {data ? (
            <DefaultPageHeader className="CategoryGridHeader">
              <Col md={12}>
                {/* Header */}
                <Row>
                  <Col md={12} xs={12}>
                    <div className="flex items-center justify-between mt-0 h-xlarge mb-2">
                      <div>
                        <div className="flex inter-large-semibold gap-x-base text-grey-40">
                          <div
                            className={`${
                              !categoryTreeDataOrderingState && 'text-grey-90'
                            } cursor-pointer`}
                            onClick={() =>
                              setCategoryTreeDataOrderingState(
                                !categoryTreeDataOrderingState
                              )
                            }
                          >
                            Blog Kategorileri
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center space-x-2">
                        <div>
                          <div>
                            <div className="flex space-x-2">
                              <button
                                className="btn btn-primary btn-small"
                                onClick={openDrawer}
                              >
                                <span className="mr-xsmall last:mr-0">
                                  <PlusIcon />
                                </span>
                                <span className="mr-xsmall last:mr-0">
                                  Yeni Blog Kategorisi Oluştur
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </DefaultPageHeader>
          ) : (
            <> </>
          )}

          <Fragment>
            {!data ? (
              <NoResult />
            ) : loading ? (
              <CategoryLoaderWrapper>
                <CategoryLoaderItem>
                  <Placeholder />
                </CategoryLoaderItem>
                <CategoryLoaderItem>
                  <Placeholder />
                </CategoryLoaderItem>
                <CategoryLoaderItem>
                  <Placeholder />
                </CategoryLoaderItem>
                <CategoryLoaderItem>
                  <Placeholder />
                </CategoryLoaderItem>
              </CategoryLoaderWrapper>
            ) : (
              <DataGridGroupingCategories
                loading={loading}
                externalData={null}
                data={data.blog_categories}
                direction={'ltr'}
                onReload={() => refetch()}
              />
            )}
          </Fragment>
        </Col>
      </Row>
    </Grid>
  );
}
