import { useState, useMemo, Fragment, useCallback, useEffect } from 'react';
import { groupBy as rowGrouper } from 'lodash';
import DataGrid, {
  Column,
  RowsChangeData,
  SortColumn,
  TextEditor,
} from 'react-data-grid';
import {
  Grid,
  Row as RowFlexBox,
  Col as ColFlexBox,
} from 'components/FlexBox/FlexBox';
import {
  REACT_APP_API_DEFAULT_IMAGE_URL,
  REACT_APP_API_IMAGE_URL,
} from 'settings/constants';
import { Props } from 'components/DataGrid/components/types';
import { ImageFormatter } from 'components/DataGrid/components/Formatters';
import {
  CheckIcon,
  ChevronDownIcon,
  CrossIcon,
  EditIcon,
  ExportIcon,
  PlusIcon,
  RemoveIcon2,
  SearchIcon,
  UploadIcon2,
} from 'components/MixingComponents/components/Icons';
import { CloseIcon } from 'assets/icons/CloseIcon';
import {
  ActionContainer,
  AlignCenter,
  CheckCloseContainer,
  filterClassname,
  filterColumnClassName,
  filterContainerClassname,
  flexCenter,
} from './style';
import { useDrawerDispatch } from 'context/DrawerContext';
import {
  Filter,
  FilterContext,
  FilterRenderer,
  inputStopPropagation,
} from './FilteredRenderer';
import { ExportButton } from './ExportButton';
import {
  exportToCsv,
  exportToPdf,
  exportToXlsx,
} from 'components/DataGrid/exportUtils';
import { EDIT_CATEGORIES_tree, GET_CATEGORIES } from '../utils';
import { useMutation } from '@apollo/client';
import { useToasts } from 'react-toast-notifications';
import { LoaderItem, LoaderWrapper } from 'components/LoaderWrapper';
import Placeholder from 'components/Placeholder/Placeholder';
import Button, { KIND } from 'components/Button/Button';
import { SIZE } from 'baseui/input';
import { DefaultPageHeader } from 'components/DataGrid/components/DefaultHeader';
import DeleteToast from 'components/DeleteToast';

export interface CategoryRow {
  id?: any;
  parent?: {
    id?: string;
    name?: string;
  };
  parent_id?: any;
  name?: any;
  slug?: any;
  order?: any;
  icon?: any;
  is_removed?: any;
  cover_image?: any;
  children?: CategoryRow[];
  products_aggregate?: {
    aggregate?: any;
    count?: any;
  };
}

function rowKeyGetter(row: CategoryRow) {
  return row.name;
}

const options = ['parent.name', 'is_campaign', 'is_removed'] || [
  {
    key: 'parent.name',
    name: 'Üst Kategoriler',
  },
  {
    key: 'is_campaign',
    name: 'Kampanyalılar',
  },
  {
    key: 'is_removed',
    name: 'Görünmeyenler',
  },
];

const getOptionName = (key: any) => {
  switch (key) {
    case 'parent.name':
      return 'Kategoriye göre grupla';
      break;
    case 'is_campaign':
      return 'Kampanyaya göre grupla';
      break;
    case 'is_removed':
      return 'Görünüme göre grupla';
      break;
    default:
      return 'Filtre';
      break;
  }
};

export default function Grouping({ direction, loading, data, onReload }: any) {
  const dispatch = useDrawerDispatch();
  const { addToast, removeAllToasts } = useToasts();
  const [rows, setRows] = useState(data);
  const [filters, setFilters] = useState<Filter>({
    name: '',
    slug: '',
    parent: {
      name: '',
    },
    enabled: false,
  });
  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);

  const [selectedRows, setSelectedRows] = useState<ReadonlySet<number>>(
    () => new Set()
  );
  const [selectedOptions, setSelectedOptions] = useState<readonly string[]>(
    [] || ([options[0]] as any)
  );
  const [expandedGroupIds, setExpandedGroupIds] = useState<
    ReadonlySet<unknown>
  >(() => new Set<unknown>(['']));
  const [showFilter, setShowFilter] = useState(false);
  const [showFilterGroupeds, setShowFilterGroupeds] = useState(false);

  const [
    editCategoriesTree,
    { loading: editCategoryLoading, error: editCategoryError },
  ] = useMutation(EDIT_CATEGORIES_tree, {
    onCompleted(complete) {
      if (complete.insert_blog_categories.affected_rows > 0) {
        addToast('Kategori Güncellendi', {
          appearance: 'success',
        });
      } else {
        addToast('Kategori güncellemesi işlemi sırasında bir hata oluştu.', {
          appearance: 'warning',
        });
        addToast('Lütfen tekrar deneyin.', {
          appearance: 'info',
        });
      }
    },
  });

  const columns = useMemo((): readonly Column<CategoryRow>[] => {
    return [
      // {
      //   key: "icon",
      //   name: "Görsel",
      //   width: 40,
      //   headerRenderer: () => <span className={AlignCenter}>Görsel</span>,
      //   formatter: ({ row }) => (
      //     <div className={flexCenter}>
      //       {" "}
      //       <ImageFormatter
      //         value={
      //           row?.icon
      //             ? REACT_APP_API_IMAGE_URL + row.icon
      //             : REACT_APP_API_DEFAULT_IMAGE_URL
      //         }
      //       />
      //     </div>
      //   ),
      // },
      {
        key: 'name',
        name: 'Kategori Adı',
        editor: TextEditor,
        headerCellClass: filterColumnClassName,
        headerRenderer: (p) => (
          <FilterRenderer<CategoryRow, unknown, HTMLInputElement> {...p}>
            {({ filters, ...rest }) => (
              <input
                {...rest}
                className={filterClassname}
                value={filters.name}
                onChange={(e: any) =>
                  setFilters({
                    ...filters,
                    name: e.target.value,
                  })
                }
                placeholder="Ad'a göre arama"
                onKeyDown={inputStopPropagation}
              />
            )}
          </FilterRenderer>
        ),
      },
      {
        key: 'slug',
        name: 'Url',
        editor: TextEditor,
        headerCellClass: filterColumnClassName,
        headerRenderer: (p) => (
          <FilterRenderer<CategoryRow, unknown, HTMLInputElement> {...p}>
            {({ filters, ...rest }) => (
              <input
                {...rest}
                className={filterClassname}
                value={filters.slug}
                onChange={(e: any) =>
                  setFilters({
                    ...filters,
                    slug: e.target.value,
                  })
                }
                placeholder={"Url'ye göre arama"}
                onKeyDown={inputStopPropagation}
              />
            )}
          </FilterRenderer>
        ),
      },

      {
        key: 'order',
        name: 'Sıralama',
        headerCellClass: filterColumnClassName,
        headerRenderer: () => <span className={AlignCenter}>Sıralama</span>,
      },
      {
        key: 'status',
        name: 'Görünürlük',
        width: 90,
        headerRenderer: () => <span className={AlignCenter}>Aktif/Pasif</span>,
        formatter: ({ row }: any) => (
          <CheckCloseContainer>
            {row.status ? (
              <Fragment>
                <CheckIcon />
                <span className="none-element">Aktif</span>
              </Fragment>
            ) : (
              <Fragment>
                <CloseIcon width={14} height={14} />
                <span className="none-element">Pasif</span>
              </Fragment>
            )}
          </CheckCloseContainer>
        ),
      },

      {
        key: 'action',
        name: 'Aksiyon',
        width: 80,
        headerRenderer: () => <span className={AlignCenter}>Aksiyon</span>,
        formatter: ({ row }) => (
          <div className={ActionContainer}>
            <div
              className="data-grid-action data-grid-remove-action"
              onClick={() => {
                addToast(
                  <DeleteToast
                    row={row}
                    titleForMessage={
                      'Blog kategorisini silmek istediğinize emin misiniz ?'
                    }
                    onDelete={onRowEditSpesificRemove}
                  />,
                  {
                    appearance: 'info',
                    autoDismiss: false,
                    id: 'DeleteToast',
                  }
                );
              }}
            >
              <span> Sil </span>
              <RemoveIcon2 size={18} />
            </div>
            <div
              className="data-grid-action data-grid-edit-action"
              onClick={() => openDrawer({ row })}
            >
              <span> Düzenle </span>
              <EditIcon size={18} />
            </div>
          </div>
        ),
      },
    ];
  }, []);

  const filteredRows = useMemo(() => {
    return rows.filter((r: { name: string; slug: string }) => {
      return (
        (filters.name
          ? r.name
              .toLocaleLowerCase()
              .includes(filters.name.toLocaleLowerCase())
          : true) &&
        (filters.slug
          ? r.slug
              .toLocaleLowerCase()
              .includes(filters.slug.toLocaleLowerCase())
          : true)
      );
    });
  }, [rows, filters]);

  const openDrawer = useCallback(
    ({ row }) =>
      dispatch({
        type: 'OPEN_DRAWER',
        drawerComponent: 'BLOG_CATEGORY_UPDATE_FORM',
        data: row,
      }),
    [dispatch]
  );

  const onSortColumnsChange = useCallback((sortColumns: SortColumn[]) => {
    setSortColumns(sortColumns.slice(-1));
  }, []);

  // const sortedRows = useMemo((): readonly CategoryRow[] => {
  //   if (sortColumns.length === 0) return rows;
  //   const { columnKey, direction } = sortColumns[0];

  //   let sortedRows: CategoryRow[] = [...rows];

  //   switch (columnKey) {
  //     case "task":
  //     case "priority":
  //     case "issueType":
  //       sortedRows = sortedRows.sort((a, b) =>
  //         a[columnKey].localeCompare(b[columnKey])
  //       );
  //       break;
  //     case "products_aggregate":
  //       sortedRows = sortedRows.sort(
  //         (a: any, b: any) => a[columnKey] - b[columnKey]
  //       );
  //       break;
  //     default:
  //   }
  //   return direction === "DESC" ? sortedRows.reverse() : sortedRows;
  // }, [rows, sortColumns]);

  function toggleOption(option: string, enabled: boolean) {
    clearFilters();

    const index = selectedOptions.indexOf(option);
    if (enabled) {
      if (index === -1) {
        setSelectedOptions((options) => [...options, option]);
      }
    } else if (index !== -1) {
      setSelectedOptions((options) => {
        const newOptions = [...options];
        newOptions.splice(index, 1);
        return newOptions;
      });
    }
    setExpandedGroupIds(new Set());
  }

  async function clearFilters() {
    setFilters({
      name: '',
      slug: '',
      parent: {
        name: '',
      },
      enabled: filters.enabled,
    });
  }

  function toggleFilters() {
    setFilters((filters) => ({
      ...filters,
      enabled: !filters.enabled,
    }));
  }

  async function onRowsChange(
    rowss: CategoryRow[],
    { indexes, column }: RowsChangeData<CategoryRow>
  ) {
    clearFilters();

    const row = rowss[indexes[0]];
    //debugger;
    if (column.key === 'name' || column.key === 'slug') {
      await editCategoriesTree({
        variables: {
          update_columns: ['name', 'slug'],
          objects: [
            {
              id: row.id,
              name: row.name,
              slug: row.slug,
            },
          ],
        },
      }).then((res) => {
        if (res.data.insert_blog_categories.affected_rows > 0) {
          let newRows = JSON.parse(JSON.stringify(rows));
          newRows = newRows.map((item) => {
            if (item.id === row.id) {
              item.name = row.name;
              item.slug = row.slug;
            }
            return item;
          });
          setRows(newRows);
        }
      });
    }
  }

  async function onRowEditSpesificRemove(row: CategoryRow) {
    await clearFilters();

    await editCategoriesTree({
      variables: {
        // "update_columns": ["is_removed"],
        // TODO: "message": "Not-NULL violation. null value in column \"slug\" violates not-null constraint" -- "path": "$.selectionSet.insert_categories.args.objects"
        update_columns: ['name', 'is_deleted', 'slug'],
        objects: [
          {
            id: row.id,
            name: row.name,
            slug: row.slug,
            is_deleted: true,
          },
        ],
      },
    }).then((res) => {
      if (res.data.insert_blog_categories.affected_rows > 0) {
        addToast('Kategori başarıyla silindi.', {
          appearance: 'info',
        });
        onReload();
        removeAllToasts();
        //setRows(rows);
      }
    });
  }

  const gridElement = (
    <DataGrid
      className={
        (filters.enabled ? filterContainerClassname : undefined) +
        ' default-grid rdg-light'
      }
      headerRowHeight={filters.enabled ? 100 : undefined}
      columns={columns}
      rows={filteredRows}
      rowHeight={55}
      rowKeyGetter={rowKeyGetter}
      selectedRows={selectedRows}
      onSelectedRowsChange={setSelectedRows}
      groupBy={selectedOptions}
      rowGrouper={rowGrouper}
      expandedGroupIds={expandedGroupIds}
      onExpandedGroupIdsChange={setExpandedGroupIds}
      defaultColumnOptions={{ resizable: true, sortable: false }}
      sortColumns={sortColumns}
      onSortColumnsChange={onSortColumnsChange}
      direction={direction}
      onRowsChange={onRowsChange}
    />
  );

  return (
    <Grid fluid={false}>
      <RowFlexBox clean={true}>
        <ColFlexBox md={12} style={{ padding: 0 }}>
          <div className="datagrid-template bg-transparent">
            {rows && (
              <DefaultPageHeader className="CategoriesGridHeader">
                <ColFlexBox md={12}>
                  {/* Filters */}
                  {rows && rows.length ? (
                    <RowFlexBox>
                      <ColFlexBox md={12} xs={12}>
                        <div className="w-full flex justify-between mb-0">
                          <div className="flex mb-0 self-end">
                            <div className="flex space-x-1 relative">
                              {/* <button
                                onClick={() => setShowFilter(!showFilter)}
                                className="bg-white flex rounded-rounded items-center space-x-1 focus-visible:outline-none focus-visible:shadow-input focus-visible:border-violet-60 mr-3"
                                type="button"
                                aria-haspopup="dialog"
                                aria-expanded="false"
                                data-state="closed"
                              >
                                <div className="flex rounded-rounded items-center bg-grey-5 border border-grey-20 inter-small-semibold px-2 h-6">
                                  Filtrele
                                  <div className="text-grey-40 ml-1 flex items-center rounded">
                                    <span className="text-current inter-small-semibold">
                                      {
                                        selectedOptions.length + (filters.enabled ? 1 : 0)
                                      }
                                    </span>
                                  </div>
                                </div>
                                <div className="flex items-center rounded-rounded bg-grey-5 border border-grey-20 inter-small-semibold p-1">
                                  <PlusIcon size="14" />
                                </div>
                              </button> */}

                              {/* ...TODO: ... */}
                              <div
                                className="transition-opacity transition-transform"
                                style={{
                                  position: 'absolute',
                                  top: '30px',
                                  left: '0px',
                                  minWidth: 'max-content',
                                  willChange: 'transform',
                                  opacity: showFilter ? 1 : 0,
                                  zIndex: showFilter ? 9999 : -1,
                                  visibility: showFilter ? 'unset' : 'hidden',
                                  // transform: 'translate3d(315px, 170px, 0px)'
                                }}
                              >
                                <div
                                  data-state="open"
                                  role="dialog"
                                  className="bg-grey-0 overflow-y-auto rounded-rounded shadow-dropdown max-w-[272px] py-4 max-h-[439px]"
                                  tabIndex={-1}
                                  data-side="bottom"
                                  data-align="center"
                                >
                                  <div className="flex px-4 pb-4 border-b border-grey-20 pb-8">
                                    <button
                                      tabIndex={-1}
                                      className="btn btn-ghost btn-small mr-3 border border-grey-20"
                                      onClick={() => setShowFilter(!showFilter)}
                                    >
                                      <span className="mr-xsmall last:mr-0">
                                        Kapat
                                      </span>
                                    </button>
                                    <button
                                      tabIndex={-1}
                                      className="btn btn-primary btn-small w-44 justify-center"
                                      onClick={() => setShowFilter(!showFilter)}
                                    >
                                      <span className={`mr-xsmall last:mr-0`}>
                                        Uygula
                                      </span>
                                    </button>
                                  </div>

                                  <div className="border-b border-grey-20 py-2 px-4 last:pb-0 last:border-0">
                                    <div className="w-full cursor-pointer inter-small-semibold">
                                      <div data-state="open" className="w-full">
                                        <button
                                          onClick={() => {
                                            if (showFilterGroupeds) {
                                              setShowFilterGroupeds(
                                                !showFilterGroupeds
                                              );
                                              setSelectedOptions([]);
                                            } else {
                                              setShowFilterGroupeds(
                                                !showFilterGroupeds
                                              );
                                            }
                                          }}
                                          type="button"
                                          aria-expanded="true"
                                          data-state="open"
                                          className="py-1.5 px-3 flex w-full items-center rounded justify-between inter-small-regular bg-white hover:bg-grey-5"
                                        >
                                          <div className="flex items-center">
                                            <div
                                              className={`w-5 h-5 flex justify-center text-grey-0 border-grey-30 border mr-2 rounded-base ${
                                                showFilterGroupeds &&
                                                'bg-current'
                                              }`}
                                            >
                                              {showFilterGroupeds && (
                                                <span className="self-center">
                                                  <CheckIcon size="16" />
                                                </span>
                                              )}
                                            </div>
                                            <input
                                              id="Status"
                                              className="hidden"
                                              type="checkbox"
                                            />
                                            <span
                                              className={`ml-2 ${
                                                showFilterGroupeds &&
                                                'inter-small-semibold'
                                              }`}
                                            >
                                              Gruplama
                                            </span>
                                          </div>
                                          <span className="text-current self-end">
                                            {showFilterGroupeds && (
                                              <ChevronDownIcon />
                                            )}
                                          </span>
                                        </button>

                                        {showFilterGroupeds && (
                                          <div
                                            data-state="open"
                                            className="w-full"
                                          >
                                            {options.map(
                                              (option: any, index) => (
                                                <div
                                                  className="w-full flex hover:bg-grey-20 my-1 py-1.5 pl-6 items-center rounded inter-small-regular"
                                                  key={index}
                                                  onClick={(e: any) =>
                                                    toggleOption(
                                                      option,
                                                      !selectedOptions.includes(
                                                        option
                                                      )
                                                    )
                                                  }
                                                >
                                                  <div
                                                    className={`w-5 h-5 flex justify-center text-grey-0 border-grey-30 border mr-3 rounded-base ${
                                                      selectedOptions.includes(
                                                        option
                                                      ) && 'bg-current'
                                                    }`}
                                                  >
                                                    {selectedOptions.includes(
                                                      option
                                                    ) ? (
                                                      <span className="self-center">
                                                        {' '}
                                                        <CheckIcon size="16" />{' '}
                                                      </span>
                                                    ) : (
                                                      <span className="self-center" />
                                                    )}
                                                  </div>
                                                  <input
                                                    type="checkbox"
                                                    className="hidden"
                                                    id={getOptionName(option)}
                                                    name={getOptionName(option)}
                                                    defaultValue={getOptionName(
                                                      option
                                                    )}
                                                    style={{
                                                      marginRight: '5px',
                                                    }}
                                                  />
                                                  {getOptionName(option)}
                                                </div>
                                              )
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  {/* ToggleFilters */}
                                  <div className="border-b border-grey-20 py-2 px-4 last:pb-0 last:border-0">
                                    <div className="w-full cursor-pointer inter-small-regular">
                                      <div
                                        data-state="closed"
                                        className="w-full"
                                      >
                                        <button
                                          onClick={toggleFilters}
                                          type="button"
                                          aria-controls="182"
                                          aria-expanded="false"
                                          data-state="closed"
                                          className="py-1.5 px-3 flex w-full items-center rounded justify-between inter-small-regular bg-white hover:bg-grey-5"
                                        >
                                          <div className="flex items-center">
                                            <div
                                              className={`w-5 h-5 flex justify-center text-grey-0 border-grey-30 border rounded-base ${
                                                filters.enabled && 'bg-current'
                                              }`}
                                            >
                                              {filters.enabled && (
                                                <span className="self-center">
                                                  {' '}
                                                  <CheckIcon size="16" />{' '}
                                                </span>
                                              )}
                                            </div>
                                            <span
                                              className={`ml-2 ${
                                                filters.enabled &&
                                                'inter-small-semibold'
                                              }`}
                                            >
                                              Aramayı{' '}
                                              {!filters.enabled
                                                ? 'Göster'
                                                : 'Gösterme'}
                                            </span>
                                          </div>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* ...TODO: ... */}

                              <button
                                onClick={toggleFilters}
                                className={`${
                                  filters.enabled
                                    ? 'bg-current border-current text-white'
                                    : 'bg-grey-5 border border-gray text-grey-50'
                                } flex items-center inter-small-semibold px-2 h-6 rounded-rounded mr-3`}
                              >
                                <span className="mr-xsmall">
                                  <SearchIcon size="14" />
                                </span>
                                <span className="mr-xsmall mr-0">
                                  Aramayı{' '}
                                  {!filters.enabled ? 'Göster' : 'Gösterme'}
                                </span>
                              </button>

                              <button
                                onClick={clearFilters}
                                className="bg-grey-5 border border-gray text-grey-50 flex items-center inter-small-semibold px-2 h-6 rounded-rounded"
                              >
                                <span className="mr-xsmall mr-0">
                                  <CrossIcon size="14" />
                                </span>
                                <span className="mr-xsmall mr-0">
                                  Aramayı Temizle
                                </span>
                              </button>
                            </div>
                          </div>

                          <div className="flex items-center space-x-2">
                            <div>
                              <div>
                                <div className="flex space-x-2">
                                  <ExportButton
                                    onExport={() =>
                                      exportToCsv(
                                        gridElement,
                                        'BC-Admin_Kategoriler.csv'
                                      )
                                    }
                                  >
                                    <>
                                      <span className="mr-xsmall last:mr-0">
                                        <ExportIcon />
                                      </span>
                                      <span className="mr-xsmall last:mr-0">
                                        CSV Çıkart
                                      </span>
                                    </>
                                  </ExportButton>
                                  <ExportButton
                                    onExport={() =>
                                      exportToXlsx(
                                        gridElement,
                                        'BC-Admin_Kategoriler.xlsx'
                                      )
                                    }
                                  >
                                    <>
                                      <span className="mr-xsmall last:mr-0">
                                        <ExportIcon />
                                      </span>
                                      <span className="mr-xsmall last:mr-0">
                                        XSLX Çıkart
                                      </span>
                                    </>
                                  </ExportButton>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ColFlexBox>
                    </RowFlexBox>
                  ) : (
                    <></>
                  )}
                </ColFlexBox>
              </DefaultPageHeader>
            )}

            <FilterContext.Provider value={filters}>
              {editCategoryLoading ? (
                <LoaderWrapper>
                  <LoaderItem>
                    <Placeholder />
                  </LoaderItem>
                </LoaderWrapper>
              ) : (
                gridElement
              )}
            </FilterContext.Provider>
          </div>
        </ColFlexBox>
      </RowFlexBox>
    </Grid>
  );
}
