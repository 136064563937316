import { StyledLoadingSpinner } from "baseui/button";
import { Fragment, useState } from "react";

export function ExportButton({
  onExport,
  children,
  ...atrrs
}: {
  onExport: () => Promise<unknown>;
  children: React.ReactChild;
}) {
  const [exporting, setExporting] = useState(false);
  return (
    <button className="btn btn-secondary btn-small"
      {...atrrs}
      disabled={exporting}
      // loading={exporting}
      onClick={async () => {
        setExporting(true);
        await onExport();
        setExporting(false);
      }}
    >
      {
        exporting ? (
          <Fragment>
            <StyledLoadingSpinner /> <span className="ml-2"> Hazırlanıyor...</span>
          </Fragment>
        ) : children
      }
    </button>

  );
}